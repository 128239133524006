const points = {
    // origin: 'https://proxy.amateum.com/',
    // test: 'https://api.rfsgr.ru/',
    test: 'https://dev-engine.amateum.com/',
    preprod: 'https://preprod-api-mf.rfs.ru/',
    prod: 'https://api-mf.rfs.ru/'
}

const domains = {
    test: 'https://dev.amateum.com/',
    preprod: 'https://preprod-vmf.rfs.ru/',
    prod: 'https://vmf.rfs.ru/'
}

// export const ENDPOINT = points[process.env.INSTANCE] || 'https://api.rfsgr.ru/'
export const ENDPOINT = points[process.env.INSTANCE] || 'https://dev-engine.amateum.com/'
export const PUBLICENDPOINT = process.env.NODE_ENV === 'production' ? domains[process.env.INSTANCE] : 'http://localhost:3000/'
// export const ENDPOINT = process.env.NODE_ENV === 'production' ? points[process.env.INSTANCE] : 'http://localhost:3123/';

export const TIP_FLOWS = {
    federation: ['structure'],
    club: []
}

export const SITE_KEY = "6Lc4vR8qAAAAABmXZzh5PHZSBEvtYf3NNVJG_5JI"
