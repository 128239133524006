import React, {useCallback, useContext, useEffect, useRef, useState} from "react";

import DataTable from "../../../Common/DataTable";
import { useHistory } from "react-router-dom";
import {deleteByUrl, getByUrl, postByUrl, putByUrl} from "../../../../../v3MethodsService";
import moment from "moment";
import {WorkspaceContext} from "../../../../../ctx";
import debounce from "lodash.debounce";
import axios from "axios";
import { ENDPOINT } from "../../../../../env";

import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import {ProgressSpinner} from "primereact/progressspinner";
import {Timeline} from "primereact/timeline";
import { AutoComplete } from "primereact/autocomplete";
import { Divider } from "primereact/divider";
import {InputText} from "primereact/inputtext";

import defaultLogo from "../../img/defaultLogo.png";
import "./style.scss";
import SideBar from "../SideBar";

const TournamentsRequestsProcessed = ({ reqPath, toast }) => {
    const wctx = useContext(WorkspaceContext)
    const history = useHistory();

    const autoCompleteRef = useRef(null);

    const isClub = !!(wctx && wctx.appSubject && wctx.appSubject.type && ['club'].includes(wctx.appSubject.type))

    const param = window.location.pathname.split('/')[2]
    const mode = window.location.search.split('&')[0]?.replace('?mode=', '')
    const path = window.location.search.split('&')[1]?.replace('path=', '')
    const id = window.location.search.split('&')[2]?.replace('id=', '')
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [queryType, setQueryType] = useState('all')

    const [visible, setVisible] = useState(false)
    const [visibleSideBar, setVisibleSideBar] = useState(false)

    const [selectedItems, setSelectedItems] = useState([]);
    const [selection, setSelection] = useState([]);
    const [apply, setApply] = useState([])
    const [declain, setDeclain] = useState([])

    const [positions, setPositions] = useState([])
    const [show, setShow] = useState(false);
    const [declainText, setDeclainText] = useState("");
    const [suggesting, setSuggesting] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState(null);
    const [playersInSquad, setPlayersInSquad] = useState([]);
    const dataTablePlayers = data?.players?.length > 0 ? [...data?.players, ...playersInSquad] : [...playersInSquad];

    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        const page = document.querySelector(".page");
        page.addEventListener("scroll", handleScroll);
        return () => {
            page.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        getByUrl('positions')
            .then(resp => {
                if (resp && resp.success) {
                    setPositions(resp.data?.map(p => p.shortName))
                }
            })
    }, []);

    const handleScroll = () => {
        if (autoCompleteRef.current) {
            autoCompleteRef.current.hide();
        }
    };

    useEffect(() => {
        getData()
    }, [param]);

    const getData = () => {
        setLoading(true)
        getByUrl(`${mode || reqPath}/${param}`)
            .then(resp => {
                if (resp && resp.success) {
                    setData({...resp.data, players: resp.data.data ? resp.data.data.length ? resp.data.data : resp.data.data.addon : resp.data.players})
                }
                setLoading(false)
            })
    }

    const updateCheckbox = (value) => {
        const newSelected = [...selectedItems]
        if (newSelected.includes(value)) {
            setSelectedItems(newSelected.filter(s => s !== value))
            return
        }
        newSelected.push(value)
        setSelectedItems(newSelected)
    }

    const selectCheckbox = (array) => {
        setSelection(array)

        const _ids = array.map(p => p._id)
        setSelectedItems(_ids)
    }

    const userItem = (item) => {
        return (
            <div className="tournamentsReqests-processed__user">
                {/*{!isClub && reqPath === 'queries' && (!mode || mode === 'queries') && <Checkbox value={item} checked={selectedItems.includes(item._id)} onChange={(e) => updateCheckbox(e.target.value?._id)}/>}*/}
                <div className="tournamentsReqests-processed__user-circle">{(item?.surname ? item?.surname[0] : "") + (item?.name ? item?.name[0] : "")}</div>
                <div className="tournamentsReqests-processed__user-container">
                    <span className="tournamentsReqests-processed__user-fio">{`${item?.surname ? item?.surname : ""} ${item?.name ? item?.name : ""} ${item?.middlename ? item?.middlename : ""}`}</span>
                    {item?.isVerified ? (
                        <Tag value="верифицирован" icon="pi pi-check" severity="success" className="side-profile__tag" />
                    ) : (
                        <Tag value={"не верифицирован"} className="side-profile__tag side-profile__tag_gray" />
                    )}
                </div>
            </div>
        );
    };

    const statusItem = (item) => {
        if (item.squadState?.unlinked) {
            return [(
                <div className="tournamentsReqests-processed__rejected">
                    <span>Отзаявлен</span>
                </div>
            ), 'withdrawn'];
        }
        if (item.squadState?.linked) {
            return [<div className="tournamentsReqests-processed__accepted">Принята</div>, 'accepted'];
        }
        if (reqPath === 'queries' && (!mode || ['queries'].includes(mode))) {
            if (apply.find(a => a._id === item._id)) {
                return [<div className="tournamentsReqests-processed__accepted">Принята</div>, 'accepted']
            }

            if (declain.find(d => d._id === item._id) || data.declined) {
                return [(
                    <div className="tournamentsReqests-processed__rejected">
                        <Tooltip target=".custom-target-icon" position="top" />
                        <span>Отклонено</span>
                        <i className="custom-target-icon pi pi-info-circle" data-pr-tooltip={item.declineReason ? item.declineReason : ""}></i>
                    </div>
                ), 'rejected']
            }

            return [<div>Необработанно</div>, 'unprocessed'];
            /*switch (item?.status) {
                case "accepted":
                    return <div className="tournamentsReqests-processed__accepted">Принята</div>;
                case "rejected":
                    return (
                        <div className="tournamentsReqests-processed__rejected">
                            <Tooltip target=".custom-target-icon" position="top" />
                            <span>Отклонено</span>
                            <i className="custom-target-icon pi pi-info-circle" data-pr-tooltip={item.rejectedMessage ? item.rejectedMessage : ""}></i>
                        </div>
                    );
                default:
                    return <div>Необработанно</div>;
            }*/
        }
        return [<div>Необработанно</div>, 'unprocessed'];
    };

    const removeReq = () => {
        confirmDialog({
            message: "Заявка будет безвозвратно удалена",
            header: "Удалить заявку?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: acceptRemove,
            reject: () => {},
        });
    };

    const acceptRemove = () => {
        setLoading(true)
        deleteByUrl(`queries/${data._id}`)
            .then(resp => {
                if (resp && resp.success) {
                    toast.show({severity: 'success', detail: 'Заявка успешно удалена', life: 3000})
                    history.push('/queries')
                }
                else {
                    toast.show({ severity: "error", summary: resp?.message || resp?.msg ||  "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
                setLoading(false)
            })
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Не отклонять" onClick={() => setShow(false)} className="p-button-text" />
                <Button label="Отклонить" autoFocus className="p-button-danger" disabled={!declainText} onClick={handleDecalin} />
            </div>
        );
    };

    const applyHandler = () => {
        setDeclain(prev => prev.filter(d => !selectedItems.includes(d._id)))
        const players = selectedItems.map(s => ({_id: s}))
        setApply(prev => {
            const mergedPlayers = [...prev, ...players];

            const uniquePlayers = mergedPlayers.reduce((acc, player) => {
                acc[player._id] = player;
                return acc;
            }, {});

            return Object.values(uniquePlayers);
        })
        setSelectedItems([])
        setSelection([])
    }

    const handleDecalin = () => {
        setShow(false)
        setApply(prev => prev.filter(d => !selectedItems.includes(d._id)))
        const players = selectedItems.map(s => ({_id: s, declined: true, declineReason: declainText}))
        setDeclain(prev => {
            const mergedPlayers = [...prev, ...players];

            const uniquePlayers = mergedPlayers.reduce((acc, player) => {
                acc[player._id] = player;
                return acc;
            }, {});

            return Object.values(uniquePlayers);
        });
        setDeclainText('')
        setSelectedItems([])
        setSelection([])
    };

    const handleVerify = () => {
        confirmDialog({
            message: "После верификации вы не сможете редактировать заявку",
            header: "Верифицировать заявку?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Верифицировать",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: acceptVerify,
            reject: () => {},
        });

    }

    const acceptVerify = () => {
        const isApply = apply?.length > 0
        const body = apply?.length > 0 ? {
            decline: declain,
            apply: apply
        } : {
            declined: true,
            declineReason: declain[0]?.declineReason || 'Причина не указана'
        }
        putByUrl(`queries/${data._id}${isApply?'?mode=apply' : ''}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast?.show({ severity: "success", summary: "Заявка верифицирована", life: 3000 });
                    history.push('/queries')
                }
                else {
                    toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 3000 });
                }
            })
    }

    const canVerify = data && data.players?.length > 0 ? !data.players.find(p => !declain.some(d => d._id === p._id) && !apply.some(a => a._id === p._id)) : true

    const handleQuery = (str) => {
        setQuery(str);
        if (str && str.length >= 3) {
            if (!suggesting) {
                verifyQuery(str, dataTablePlayers);
            }
        } else {
            setSuggesting(false);
            setSuggestions([]);
        }
    };

    const verifyQuery = useCallback(
        debounce((q, players) => {
            setSuggesting(true);
            const queryString = q.trim();

            getByUrl(`players?query=${encodeURIComponent(queryString)}&mode=withSquads`).then((resp) => {
                setSuggesting(false);
                if (resp && resp.data) {
                    let sugg = resp.data.filter(p => p._id && !players.some(s => s._id?.toString() === p._id.toString()));

                    if (sugg.length === 0) {
                        sugg = ["notfound"];
                    } else {
                        const active = sugg.filter(s => !s.squads?.length);
                        const notactive = sugg.filter(s => s.squads?.length > 0);

                        sugg = [...active];

                        if (notactive.length) {
                            sugg.push("notactive", ...notactive);
                        }
                    }

                    sugg.push("new");

                    setSuggestions(sugg);
                }
            });
        }, 800),
        []
    );

    const itemTemplate = (item, idx) => {
        return item === "new" ? (
            <div className="tournamentsReqests-processed__create">
                <Divider align="center">или</Divider>
                <div className="tournamentsReqests-processed__create-content">
                    <Button className="tournamentsReqests-processed__create-btn p-button-outlined" label="Создать профиль игрока" onClick={() => setVisible(true)} />
                </div>
            </div>
        ) : item === "notfound" ? (
            <div className="tournamentsReqests-new__notfound">К сожалению, по вашему запросу ничего не найдено</div>
        ) : item === 'notactive' ? (
            <div className="tournamentsReqests-processed__create">
                <Divider align="center">недоступные игроки, заявленные за другую команду</Divider>
            </div>
        ) : (
            <div className={`tournamentsReqests-processed__template ${item.squads?.length > 0 ? 'tournamentsReqests-processed__template_notactive' : ''}`}>
                {userItem(item)}
                <span>{item.birthday}</span>
                {item.squads?.length > 0 && (
                    <div className='tournamentsReqests-processed__squads'>
                        {item.squads?.map(s => (
                            <div className='tournamentsReqests-processed__squad'><img src={s.team?.emblem || defaultLogo}/>{s.team?.name}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData({...data, [key]: {...data[key], [subKey]: val}});
        } else {
            setData({...data, [key]: val});
        }
        setIsChanged(true)
    };

    const addPlayer = (e) => {
        setSelection(null);
        if (e.value.squads?.length > 0 || e.value === 'notactive') {
            return;
        }
        if (e.value._id) {
            const newPlayers = data.players ? [...data.players] : [];
            newPlayers.unshift(e.value);
            updateData(newPlayers, "players");
            return;
        }
        setVisibleSideBar(true);
    };

    const autocompleteHeader = (
        <>
            <AutoComplete
                ref={autoCompleteRef}
                placeholder={"Для добавления игрока вводите ФИО "}
                field="name"
                value={selection}
                suggestions={suggestions}
                completeMethod={(e) => handleQuery(e.query)}
                onChange={(e) => setSelection(e.value)}
                itemTemplate={itemTemplate}
                onSelect={addPlayer}
                panelClassName="tournaments-requests-panel-processes tournaments-requests-panel"
                // scrollHeight="1000px"
            />
            {!suggesting && <i className="pi pi-search" />}
        </>
    );

    const autoComplete = () => {
        return <Column header={autocompleteHeader} headerClassName="data-table__search" bodyStyle={{ display: "none" }} />;
    };  

    const sendReq = () => {
        confirmDialog({
            message: "После отправления вы не сможете удалить и редактировать заявку",
            header: "Отправить заявку?",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Отправить",
            rejectLabel: "Не отправлять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: acceptReq,
            reject: () => {},
        });
    };

    const acceptReq = async () => {
        setLoading(true);
        // const reqBody = {
        //     data: data.players,
        //     teamId: data.team._id,
        //     tournamentId: data.tournament._id,
        //     // seasonId: data.tournament?.seasonId,
        //     // leagueId: data.tournament?.leagueId
        // };
        // postByUrl("queries", reqBody).then((resp) => {
        //     if (resp && resp.success) {
        //         toast?.show({ severity: "success", detail: "Заявка успешно отправлена", life: 3000 });
        //         history.push("/queries");
        //     } else {
        //         toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
        //     }
        //     setLoading(false);
        // });

        const requestBody = {
            mode: 'update', // mode,
            // manage: true, // manage,
            data: data.players.filter(p => !p.squadState?.unlinked),
            teamId: data.team._id,
            tournamentId: data.tournament._id,
            squadId: data ? data._id : null,
            // players: mode === 'update' ? {
            //     added: data.players.filter(p => uuidValidate(p.id)),
            //     unlinked: data.players.filter(p => {
            //         const raw = rawPlayers.find(_p => _p._id === p._id)
            //         return (raw && !raw.squadState.unlinked && p.squadState.unlinked)
            //     }),
            //     updated: data.players.filter(p => {
            //         const raw = rawPlayers.find(_p => _p._id === p._id)
            //         return (raw && p.squadState && (
            //             (raw.squadState.number !== p.squadState.number) || (raw.squadState.unlinked && !p.squadState.unlinked)
            //         ))
            //     })
            // } : data.players,
            // headquarters: mode === 'update' ? {
            //     added: data.headquarters.filter(p => uuidValidate(p.id)),
            //     unlinked: data.headquarters.filter(p => {
            //         const raw = rawHeadquarters && rawHeadquarters.length ? rawHeadquarters.find(_p => _p._id === p._id) : null
            //         return (raw && !raw.squadState.unlinked && p.squadState.unlinked)
            //         //return false
            //     }),
            //     updated: data.headquarters.filter(p => {
            //         const raw = rawHeadquarters && rawHeadquarters.length ? rawHeadquarters.find(_p => _p._id === p._id) : null
            //         return (raw && p.squadState && raw.squadState.unlinked && !p.squadState.unlinked)
            //         //return false
            //     })
            // } : data.headquaters
        }

        const resp = await axios.post(`${ENDPOINT}v2/applyManualSquad`, requestBody, {
            headers: {
                authorization: localStorage.getItem('_amateum_auth_tkn'),
                signedby: localStorage.getItem('_amateum_auth_tkn')
            }
        })

        if (resp && resp.data && resp.data.success) {
            toast?.show({ severity: "success", detail: "Заявка успешно отправлена", life: 3000 });
            if (mode) {
                history.push(`/${path}/${id}?mode=${path === 'clubs' ? 'application' : 'teams'}`)
            } else {
                history.push("/queries");
            }
        } else {
            toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
        }
        setLoading(false);
    };

    const addNewPlayer = (player) => {
        const newPlayers = data.players ? [...data.players] : [];
        newPlayers.unshift(player);
        updateData(newPlayers, "players");
    };

    const updatePlayer = (value, playerId, key) => {
        const players = data?.players?.map((p) => (p._id === playerId ? { ...p, squadState: p.squadState ? { ...p.squadState, [key]: value } : {[key]: value} } : p));
        updateData(players, "players");
    };

    const customInput = (item) => {
        return <InputText value={item.squadState?.number || ''} placeholder="Номер" onChange={(e) => updatePlayer(e.target.value, item._id, "number")} keyfilter="int" maxLength={2} />;
    };

    const customDropdown = (item) => {
        return <Dropdown onChange={(e) => updatePlayer(e.value, item._id, "position")} placeholder="Позиция" emptyMessage={"Ничего не найдено"} value={item.squadState?.position || ""} options={positions} />;
    };
    
    return loading ? <ProgressSpinner className='loading' animationDuration='1s'/> : !!data && (
        <div className="tournamentsReqests-processed">
            <SideBar visible={visibleSideBar} setVisible={setVisibleSideBar} addPlayer={addNewPlayer} toast={toast} />
            <Dialog header="Причина отклонения" visible={show} style={{ maxWidth: "620px", width: '100%' }} footer={renderFooter} onHide={() => setShow(false)} className="verifyModal__dialog">
                <InputTextarea placeholder="Опишите причину" value={declainText} onChange={(e) => setDeclainText(e.target.value)} />
            </Dialog>
            <Dialog
                header="История изменений"
                className="tournamentsReqests-list__dialog"
                visible={visible}
                style={{width: "50vw"}}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className='tournamentsReqests-processed__timeline_container'>
                    <Timeline value={data.queries && data.queries.length ? mapQueriesToTimeLine(data.queries) : []} className={'tournamentsReqests-processed__timeline'} content={customizedContent} marker={customizedMarker}/>
                </div>
                {/* <div>
                    {data.queries?.map((item, idx) => (
                        <div className="tournamentsReqests-list__tabs">
                            <div className="tournamentsReqests-list__tab" key={idx}>
                                <div className="tournamentsReqests-list__tab-container">
                                    <div className="tournamentsReqests-list__tab-top">
                                        <span className="tournamentsReqests-list__tab-title">
                                            {item.data?.addon?.length > 0 ? `Добавлено ${item.data?.addon?.length} игрока` : 'Заявка в сезон'}
                                        </span>
                                    </div>
                                    <span>{`Внес изменения ${item?.handler?.name}`}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}
            </Dialog>
            <div className="tournamentsReqests-processed__tour">
                <img src={defaultLogo} className="tournamentsReqests-processed__tour-logo"/>
                <div className="tournamentsReqests-processed__tour-item">
                    <span className="tournamentsReqests-processed__tour-muted">Заявка на</span>
                    <span className="tournamentsReqests-processed__tour-title">{data.tournament?.name}</span>
                    <span className="tournamentsReqests-processed__tour-text">{data.tournament?.league?.name}</span>
                </div>
            </div>
            <div>
                <div className="tournamentsReqests-processed__team">
                    <div className="tournamentsReqests-processed__team-req">
                    <img src={defaultLogo} className="tournamentsReqests-processed__team-logo" />
                        <div className="tournamentsReqests-processed__team-item">
                            <span className="tournamentsReqests-processed__team-muted">Игроки заявлены от команды</span>
                            <span className="tournamentsReqests-processed__team-title">{data.team?.name}</span>
                        </div>
                    </div>
                    {(!!data.handledAt || !!data.players?.length) && (
                        <Dropdown 
                            options={queryTypes} 
                            // placeholder="Все статусы заявок" 
                            emptyMessage={"Ничего не найдено"} 
                            appendTo={"self"}  
                            optionLabel="label"
                            optionValue="key"
                            value={queryType}
                            onChange={e => setQueryType(e.value)}
                        />
                    )}
                </div>
                <DataTable
                    noCreateBtn
                    autoComplete={!isClub && (reqPath === 'squads' || mode === 'squads') && autoComplete}
                    columns={[
                        { field: "player", header: "Игрок" },
                        { field: "birthday", header: "Дата рождения", className: "secondary" },
                        { field: "number", header: "Номер", className: "secondary" },
                        { field: "position", header: "Позиции", className: "secondary" },
                        { field: "status", header: "Статус заявки", className: "secondary" },
                        !!data.handledAt && { field: "date", header: "Статус обновлен", className: "secondary" },
                    ]}
                    rows={data.players?.length > 0 ? data.players?.map((row) => ({
                        ...row,
                        player: { custom: userItem(row), value: row?.name },
                        birthday: { value: row?.birthday },
                        number: { value: (!isClub && (reqPath === 'squads' || mode === 'squads') && !row?.squadState?.linked) ? customInput(row) : row?.number || row?.num ||  row?.squadState?.number, className: "secondary" },
                        position: { value: (!isClub && (reqPath === 'squads' || mode === 'squads') && !row?.squadState?.linked) ? customDropdown(row) : row?.position ||  row?.squadState?.position},
                        status: { custom: statusItem(row)[0], value: row?.status || statusItem(row)[1]},
                        date: { value: moment(data.handledAt).format('DD.MM.YYYY') },
                    })).filter(f => queryType !== 'all' ? f.status.value == queryType : f) : []}
                    paginator={data.players?.length > 8}
                    perPage={8}
                    customDataColumn={() => CustomDataColumn(isClub, data._id, getData, toast)}
                    selection={!isClub && reqPath === 'queries' && (!mode || mode === 'queries') ? selection : false}
                    onSelectionChange={selectCheckbox}
                />
            </div>
            <div className="clubs-detail__buttons">
                {!data.handledAt && !!isClub && reqPath === 'queries' && (!mode || mode === 'queries') && <Button label="Удалить заявку" className="clubs-detail__button p-button-danger" onClick={removeReq} />}
                {selectedItems?.length ? (
                    <div className="clubs-detail__button">
                        <Button className="p-button-danger" label="Отклонить" onClick={() => setShow(true)}/>
                        <Button label="Принять" onClick={applyHandler}/>
                    </div>
                ) : (
                    <>
                        {!isClub && (reqPath === 'squads' || mode === 'squads') && isChanged && (
                            <Button
                                disabled={!data.team || !data.tournament || !(data.players && data.players.length > 0)}
                                label="Отправить заявку"
                                className="clubs-detail__button"
                                onClick={sendReq}
                            />
                        )}

                        <div className="clubs-detail__button">
                            {data.queries?.length > 0 && <Button label="История изменений" icon="pi pi-book" className="p-button-outlined" onClick={() => setVisible(true)} />}
                            {!isClub && reqPath === 'queries' && (!mode || mode === 'queries') && <Button label="Верифицировать заявку" onClick={handleVerify} disabled={!canVerify}/>}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const CustomDataColumn = (isClub, squadId, getData, toast) => {
    const op = useRef(null);
    const opBan = useRef(null);
    const [selectedRow, setSelectedRow] = useState(null)

    const clickOp = (row) => {
        setSelectedRow(row)
    }

    const handleUnlink = () => {
        const body = {
            action: 'unlinkedOne',
            playerId: selectedRow._id
        }

        putByUrl(`squads/${squadId}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast?.show({severity: 'success', detail: 'Игрок успешно отзаявлен', life: 3000})
                    getData()
                }
                else {
                    toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            })
    }

    const handleLink = () => {
        const body = {
            action: 'restore',
            players: [{_id: selectedRow._id}]
        }

        putByUrl(`squads/${squadId}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast?.show({severity: 'success', detail: 'Игрок успешно восстановлен', life: 3000})
                    getData()
                }
                else {
                    toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            })
    }

    const removePlayer = () => {
        const body = {
            action: 'removeOne',
            playerId: selectedRow._id
        }

        putByUrl(`squads/${squadId}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast?.show({severity: 'success', detail: 'Игрок успешно удален', life: 3000})
                    getData()
                }
                else {
                    toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            })
    }

    return (
        <Column
            headerClassName="data-table__action"
            bodyClassName="data-table__action"
            body={(rowData, idx) => {
                return rowData.squadState?.unlinked ? (
                    <>
                        <i className="pi pi-ban" style={{ color: "#FF3D32" }} onClick={(e) => {opBan.current.toggle(e); clickOp(rowData)}}></i>
                        <OverlayPanel ref={opBan}>
                            <div className="column__ban">
                                <span className="column__ban-title">Игрок отозван из заявки</span>
                                {/*<div className="column__ban-item">
                                    <span className="column__ban-text">Кто отозвал</span>
                                    <span className="column__ban-text muted">Дмитрий Пархоменко</span>
                                </div>*/}
                                <div className="column__ban-item">
                                    <span className="column__ban-text">Дата</span>
                                    <span className="column__ban-text muted">{moment(rowData.squadState?.unlinked, 'YY-MM-DD').format('DD.MM.YYYY')}</span>
                                </div>
                                <div className="column__ban-btns">
                                    <Button label="Вернуть к участию" onClick={handleLink}/>
                                    <Button label="Удалить из заявки" className="p-button-danger" onClick={removePlayer}/>
                                </div>
                            </div>
                        </OverlayPanel>
                    </>
                ) : rowData.squadState?.linked? (
                    <>
                        <i className="pi pi-ellipsis-v" onClick={(e) => {op.current.toggle(e); clickOp(rowData)}}></i>
                        <OverlayPanel ref={op}>
                            <div className="column__overlay">
                                <span className="column__overlay-item" onClick={handleUnlink}>Отозвать из заявки</span>
                            </div>
                        </OverlayPanel>
                    </>
                ) : null;
            }}
        />
    );
};

const mapQueriesToTimeLine = (queries) => {
    const mapd = [];
    for (let query of queries) {
        const { createdAt, user, handledAt, handler, archived, type, data } = query;
        // if (type === 'season') {
        //     continue
        // }
        const len = type === 'season' ? 0 : type === 'addon' ? data.addon?.length : data?.length;

        const obj = {
            name: `Внес изменения ${user?.name || ''} ${user?.surname || ''}`,
            date: moment(createdAt).format('DD.MM.YYYY'),
            action: type === 'season' ? `Создана` : `Добавлен${formatAction(len)} ${len} ${formatPlrs(len)}`,
            handled: handledAt
        }
        mapd.push(obj)
        if (handledAt) {
            const obj2 = {
                name: handler ? `Внес изменения ${handler.name || ''} ${handler.surname || ''}` : 'нет данных кем',
                date: moment(handledAt).format('DD.MM.YYYY'),
                action: archived ? type === 'season' ? `Отклонена` : `Отклонен${formatAction(len)} ${len} ${formatPlrs(len)}` : type === 'season' ? `Обработана` : `Обработан${formatAction(len)} ${len} ${formatPlrs(len)}`,
                handled: true
            }
            mapd.push(obj2)
        }
    }
    return mapd
}

const queryTypes = [
    {
        key: 'all',
        label: 'Все статусы заявок'
    },
    {
        key: 'accepted',
        label: 'Принято'
    },
    {
        key: 'rejected',
        label: 'Отклонено'
    },
    {
        key: 'unprocessed',
        label: 'Не обработано'
    },
    {
        key: 'withdrawn',
        label: 'Отзаявлено'
    },
    // {
    //     key: 'squad',
    //     label: 'Заявка игроков в состав коамнды на турнир'
    // },
    // {
    //     key: 'season',
    //     label: 'Заявка команды в турнир'
    // },
    // {
    //     key: 'addon',
    //     label: 'Дозаявка / отзаявка игроков в состав'
    // },
]

const formatAction = len => {
    return len > 1 ? 'о' : ''
}

const formatPlrs = length => {
    const ended = ['', 'а', 'ов'];
    let len = length > 20 ? length % 10 : length;
    let ind = 2;
    if (len === 1) {
        ind = 0
    } else if (len > 1 && len < 5) {
        ind = 1
    }
    return `игрок${ended[ind]}`;
}

const customizedContent = (item) => {
    return (
        <div className='timeline-event'>
            <div className='date'>{item.action} <span>{item.date}</span></div>
            <div className='name'>{item.name}</div>
            {item.handled ? null : <div className='date handled'>(не обработана)</div>}
        </div>
    );
};

const customizedMarker = (item) => {
    return (
        <div className={item.action.includes('отклонен') ? "custom-marker-red" : "custom-marker-blue"}></div>
    );
};

// const positions = ["ФРВ", "ЛФА", "ПФА", "ЛП", "ПП", "ЦАП", "ЦП", "ЦОП", "ЛЗ", "ЦЗ", "ПЗ", "ВРТ"];

export default TournamentsRequestsProcessed;
