import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { ENDPOINT } from "../../../../../env";
import { GlobalContext, WorkspaceContext } from "../../../../../ctx";
import { getByUrl, postByUrl } from "../../../../../v3MethodsService";
import debounce from "lodash.debounce";

import SideBar from "../SideBar";
import DataTable from "../../../Common/DataTable";

import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputNumber } from "primereact/inputnumber";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";

import "./style.scss";

const TournamentsRequestsNew = ({ toast, copyData, setCopyData }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token ? global.auth.token : "";
    const wctx = useContext(WorkspaceContext);
    const history = useHistory();
    const autoCompleteRef = useRef(null);
    const [data, setData] = useState({ ...initData });
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState(null);
    const [suggesting, setSuggesting] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [visible, setVisible] = useState(false);

    const [positions, setPositions] = useState([])
    const [options, setOptions] = useState(null);

    const [playersInSquad, setPlayersInSquad] = useState([]);

    const dataTablePlayers = data.players?.length > 0 ? [...data.players, ...playersInSquad] : [...playersInSquad];

    useEffect(() => {
        setLoading(true);
        getByUrl("leagues").then((resp) => {
            if (resp && resp.success) {
                const leagues = resp.data;
                axios
                    .get(`${ENDPOINT}v2/list/clubs`, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((response) => {
                        if (response && response.data) {
                            setOptions({ ...response.data, teams: response.data.club?.teams, league: leagues?.find((l) => l._id === response.data.club?.federationId), leagues: leagues });
                            setData((prev) => ({ ...prev, federationId: response.data.club?.federationId, clubId: response.data.club?._id }));
                        }
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        });
    }, [token]);

    useEffect(() => {
        getByUrl('positions')
            .then(resp => {
                if (resp && resp.success) {
                    setPositions(resp.data?.map(p => p.shortName))
                }
            })
    }, []);

    const handleScroll = () => {
        if (autoCompleteRef.current) {
            autoCompleteRef.current.hide();
        }
    };

    useEffect(() => {
        const page = document.querySelector(".page");
        page.addEventListener("scroll", handleScroll);
        return () => {
            page.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (copyData && copyData.reqPath && copyData.id && options && options.teams) {
            setLoading(true);
            getByUrl(`${copyData.reqPath}/${copyData.id}`).then((resp) => {
                if (resp && resp.success) {
                    const players = (resp.data.data || resp.data.players || [])?.map((p) => ({
                        _id: p._id,
                        birthday: p.birthday,
                        middlename: p.middlename,
                        name: p.name,
                        surname: p.surname,
                        number: p.squadState?.number || null,
                        position: p.squadState?.position || null,
                    }));
                    setData((prev) => ({ ...prev, team: options.teams.find((t) => t._id === resp.data.team?._id), players: players }));
                }
                setLoading(false);
            });
        }
    }, [copyData, options]);

    useEffect(() => {
        if (data.team?._id && data.tournament?._id) {
            getByUrl(`teamSquadInTourn/${data.team?._id}?tournamentId=${data.tournament?._id}`).then((resp) => {
                if (resp && resp.success) {
                    setPlayersInSquad(resp.data.players);
                }
            });
        }
    }, [data?.team, data?.tournament]);

    const handleQuery = (str) => {
        setQuery(str);
        if (str && str.length >= 3) {
            if (!suggesting) {
                verifyQuery(str, dataTablePlayers);
            }
        } else {
            setSuggesting(false);
            setSuggestions([]);
        }
    };

    const verifyQuery = useCallback(
        debounce((q, players) => {
            setSuggesting(true);
            const queryString = q.trim();

            getByUrl(`players?query=${encodeURIComponent(queryString)}&mode=withSquads`).then((resp) => {
                setSuggesting(false);
                if (resp && resp.data) {
                    let sugg = resp.data.filter(p => p._id && !players.some(s => s._id?.toString() === p._id.toString()));

                    if (sugg.length === 0) {
                        sugg = ["notfound"];
                    } else {
                        const active = sugg.filter(s => !s.squads?.length);
                        const notactive = sugg.filter(s => s.squads?.length > 0);

                        sugg = [...active];

                        if (notactive.length) {
                            sugg.push("notactive", ...notactive);
                        }
                    }

                    sugg.push("new");

                    setSuggestions(sugg);
                }
            });
        }, 800),
        []
    );

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else {
            setData({ ...data, [key]: val });
        }
    };

    const userItem = (item) => {
        return (
            <div className="tournamentsReqests-new__user">
                <div className="tournamentsReqests-new__user-circle">{(item?.surname ? item?.surname[0] : "") + (item?.name ? item?.name[0] : "")}</div>
                <div className="tournamentsReqests-new__user-container">
                    <span className="tournamentsReqests-new__user-fio">{`${item?.surname ? item?.surname : ""} ${item?.name ? item?.name : ""} ${item?.middlename ? item?.middlename : ""}`}</span>
                    {item?.isVerified ? (
                        <Tag value="верифицирован" icon="pi pi-check" severity="success" className="side-profile__tag" />
                    ) : (
                        <Tag value={"не верифицирован"} className="side-profile__tag side-profile__tag_gray" />
                    )}
                </div>
            </div>
        );
    };

    const itemTemplate = (item, idx) =>
        item === "new" ? (
            <div className="tournamentsReqests-new__create">
                <Divider align="center">или</Divider>
                <div className="tournamentsReqests-new__create-content">
                    <Button className="tournamentsReqests-new__create-btn p-button-outlined" label="Создать профиль игрока" onClick={() => setVisible(true)} />
                </div>
            </div>
        ) : item === "notfound" ? (
            <div className="tournamentsReqests-new__notfound">К сожалению, по вашему запросу ничего не найдено</div>
        ) : item === 'notactive' ? (
            <div className="tournamentsReqests-processed__create">
                <Divider align="center">недоступные игроки, заявленные за другую команду</Divider>
            </div>
        ) : (
            <div className={`${item.squads?.length > 0 ? 'tournamentsReqests-processed__template tournamentsReqests-processed__template_notactive' : 'tournamentsReqests-new__template'}`}>
                {userItem(item)}
                <span>{item.birthday}</span>
                {item.squads?.length > 0 && (
                    <div className='tournamentsReqests-processed__squads'>
                        {item.squads?.map(s => (
                            <div className='tournamentsReqests-processed__squad'><img src={s.team?.emblem}/>{s.team?.name}</div>
                        ))}
                    </div>
                )}
            </div>
        );

    const addPlayer = (e) => {
        setSelectedItem(null);
        if (e.value.squads?.length > 0 || e.value === 'notactive') {
            return;
        }
        if (e.value._id) {
            const newPlayers = data.players ? [...data.players] : [];
            newPlayers.push(e.value);
            updateData(newPlayers, "players");
            return;
        }
        setVisible(true);
    };

    const autocompleteHeader = (
        <>
            <AutoComplete
                ref={autoCompleteRef}
                placeholder={"Для добавления игрока вводите ФИО "}
                field="name"
                value={selectedItem}
                suggestions={suggestions}
                completeMethod={(e) => handleQuery(e.query)}
                onChange={(e) => setSelectedItem(e.value)}
                itemTemplate={itemTemplate}
                onSelect={addPlayer}
                panelClassName="tournaments-requests-panel"
                // scrollHeight="1000px"
            />
            {!suggesting && <i className="pi pi-search" />}
        </>
    );

    const autoComplete = () => {
        return <Column header={autocompleteHeader} headerClassName="data-table__search" bodyStyle={{ display: "none" }} />;
    };

    const updatePlayer = (value, playerId, key) => {
        const players = data?.players?.map((p) => (p._id === playerId ? { ...p, [key]: value } : p));
        updateData(players, "players");
    };

    const customInput = (item) => {
        return <InputText value={item.number} placeholder="Номер" onChange={(e) => updatePlayer(e.value, item._id, "number")} keyfilter="int" maxLength={2} />;
    };

    const customDropdown = (item) => {
        return <Dropdown onChange={(e) => updatePlayer(e.value, item._id, "position")} placeholder="Позиция" emptyMessage={"Ничего не найдено"} value={item.position || ""} options={positions} />;
    };

    const sendReq = () => {
        confirmDialog({
            message: "После отправления вы не сможете удалить и редактировать заявку",
            header: "Отправить заявку?",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Отправить",
            rejectLabel: "Не отправлять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: acceptReq,
            reject: () => {},
        });
    };

    const acceptReq = () => {
        setLoading(true);
        const reqBody = {
            data: data.players,
            teamId: data.team._id,
            tournamentId: data.tournament._id,
            // seasonId: data.tournament?.seasonId,
            // leagueId: data.tournament?.leagueId
        };
        postByUrl("queries", reqBody).then((resp) => {
            if (resp && resp.success) {
                toast?.show({ severity: "success", detail: "Заявка успешно отправлена", life: 3000 });
                history.push("/queries");
            } else {
                toast.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
            setLoading(false);
        });
    };

    const handleRemovePlayer = (e, item) => {
        e.stopPropagation();
        updateData(
            data.players.filter((p) => p._id !== item._id),
            "players"
        );
    };

    const addNewPlayer = (player) => {
        const newPlayers = data.players ? [...data.players] : [];
        newPlayers.push(player);
        updateData(newPlayers, "players");
    };

    return loading ? (
        <ProgressSpinner className="loading" animationDuration="1s" />
    ) : (
        !!data && !!options && (
            <div className="tournamentsReqests-new">
                <SideBar visible={visible} setVisible={setVisible} addPlayer={addNewPlayer} toast={toast} />
                <div className="tournamentsReqests-new__card">
                    <div className="tournamentsReqests-new__title">Заявка</div>
                    <div className="tournamentsReqests-new__inputs">
                        {inputs.map((item, idx) => (
                            <div className="tournamentsReqests-new__container" key={idx}>
                                <label className="tournamentsReqests-new__label">{item.label}</label>
                                {item.key === "league" ? (
                                    <InputText value={options[item.key]?.name} placeholder={item.placeholder} disabled={item.disabled} />
                                ) : (
                                    <Dropdown
                                        value={data[item.key]}
                                        options={options[`${item.key}s`]}
                                        onChange={(e) => updateData(e.target.value, item.key)}
                                        emptyMessage={"Ничего не найдено"}
                                        placeholder={item.placeholder}
                                        optionLabel="name"
                                        appendTo={"self"}
                                        disabled={item.disabled}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <DataTable
                    search
                    noCreateBtn
                    autoComplete={autoComplete}
                    searchPlaceholder="Для добавления игрока вводите ФИО "
                    rows={
                        dataTablePlayers?.map((row) => ({
                            ...row,
                            player: { custom: userItem(row), value: row?.name },
                            birthday: { value: row?.birthday },
                            number: {
                                custom: row?.squadState ? row?.squadState?.number : customInput(row),
                            },
                            postion: { custom: row?.squadState ? row?.squadState?.position : customDropdown(row) },
                        })) || []
                    }
                    columns={[
                        { field: "player", header: "Игрок" },
                        { field: "birthday", header: "Дата рождения" },
                        { field: "number", header: "Номер" },
                        { field: "postion", header: "Позиция", className: "tournamentsReqests-new__dropdown" },
                    ]}
                    perPage={8}
                    paginator={data.players?.length > 8}
                    handleRemove={handleRemovePlayer}
                />
                <Button
                    disabled={!data.federationId || !data.team || !data.tournament || !(data.players && data.players.length > 0)}
                    label="Отправить заявку"
                    className="clubs-detail__button"
                    onClick={sendReq}
                />
            </div>
        )
    );
};

const inputs = [
    { label: "Лига", placeholder: "Выберите лигу", key: "league", disabled: true },
    { label: "Команда", placeholder: "Выберите команду", key: "team" },
    { label: "Турнир", placeholder: "Выберите турнир", key: "tournament" },
];

const initData = {
    type: "season", // тип заявки
    clubId: null, //идентификатор клуба
    userId: null, //идентификатор автора заявки
    relatedTo: null, //ид  родительской заявки
    createdAt: null, //дата созданий
    handledBy: null, //кем обработана заявки
    handledAt: null, //когда обработана заявка
    data: null, //данные заявки разная в зависимости от типа
    headquarters: null, //головной офис
    archived: false, //з
    fixed: null,
    federationId: null,
    players: [],
};

// const positions = ["ФРВ", "ЛФА", "ПФА", "ЛП", "ПП", "ЦАП", "ЦП", "ЦОП", "ЛЗ", "ЦЗ", "ПЗ", "ВРТ"];

export default TournamentsRequestsNew;
