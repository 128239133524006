import React, {useContext, useEffect, useRef, useState} from 'react'

import { ToolbarContext } from '../../Components/Toolbar/ctx'
import { GlobalContext } from "../../ctx";

import Toolbar from '../../Components/Toolbar'
import SideProfile from "../../Components/v2/SideProfile";
import NoticesModal from "../../Components/v2/Notices/Modal";

import {Toast} from "primereact/toast";

import './style.scss'
import './pixel-ratios.scss'

const Desktop = ({ sideblock, top, body, subject }) => {
    const toast = useRef(null)
    const [global, setGlobal] = useContext(GlobalContext)
    const noVerify = global && global.noVerify
    const profileData = global && global.profile
    const notices = global && global.notices

    const [toolbar, setToolbar] = useState({
        path: null,
        filters: {},
        data: {}
    })

    useEffect(() => {
        if (noVerify) {
            setGlobal(prev => ({ ...prev, isOpenSideProfile: true}))
        }
    }, [noVerify]);

    useEffect(() => {
        setGlobal(prev => ({...prev, toast: toast.current}))
    }, [])

    const closeModal = () => {
        setGlobal(prev => ({...prev, notices: null}))
    }

    return (
        <div className='layout-desktop'>
            <Toast ref={toast} position="top-right"/>
            <div className='aside'>
                {sideblock}
            </div>
            <SideProfile visible={global.isOpenSideProfile} target='self' onHide={() => setGlobal(prev => ({ ...prev, isOpenSideProfile: false}))} data={profileData} noVerify={noVerify}/>
            <NoticesModal state={notices} onClose={closeModal} toast={toast}/>

            <ToolbarContext.Provider
                value={{
                    toolbar,
                    setToolbar,
                    setFilter: (k, v) => setToolbar({
                        ...toolbar,
                        filters: {
                            ...toolbar.filters,
                            [k]: v
                        }
                    }),
                    setToolbarData: (data) => setToolbar({
                        ...toolbar,
                        data: data
                    })
                }}>
                <div className='page'>
                    {top}
                    <Toolbar subject={subject}/>

                    <div className='container'>
                        {body}
                    </div>
                </div>
            </ToolbarContext.Provider>
        </div>
    )
}

export default Desktop
